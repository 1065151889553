import * as types from './actionTypes'

const initialState = {
  order: [],
  orderMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  status: [],
  search: {
    keyword: '',
    limit: 10,
    page: 1,
    sort: 'desc',
    order_filter: 'updated_at',
    filter: '',
    status: '',
  },
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.CREATE_ORDER_MANAGEMENT:
      return {
        ...state,
        details: payload,
      }
    case types.UPDATE_ORDER_MANAGEMENT:
      return {
        ...state,
        details: payload,
      }
    case types.DELETE_ORDER_MANAGEMENT:
      return state
    case types.SET_STATUS_DATA:
      return {
        ...state,
        status: [...payload],
      }
    case types.SET_SEARCH_CRITERIA: {
      const currentPage = formulateCurrentPage(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        order: payload.data,
        orderMeta: {
          totalCount: payload.meta.total,
          currentPage: currentPage,
          lastPage: payload.meta.lastPage,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: currentPage,
        },
      }
    }
    case types.SET_UPDATE_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}
export default reducer
