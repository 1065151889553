import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '0',
    boxShadow: 'unset',
  },
  emptyLabel: {
    color: theme.palette.green,
    textAlign: 'center',
    height: '50vh',
    paddingTop: '25vh',
  },
  card: {
    height: 'auto !important',
    boxShadow: 'unset',
  },
  cardMedia: {
    borderRadius: 10,
  },
  cardTitle: {
    color: theme.palette.green,
  },
  cardStatus: {
    color: 'white',
    backgroundColor: theme.palette.green,
    fontSize: 11,
    fontWeight: 'bold',
    padding: 0,
    marginRight: 5,
  },
  customPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableEyeIcon: {
    color: theme.palette.green,
    height: 25,
  },
  button: {
    minWidth: 'unset',
    '&:disabled': {
      '& $tableEyeIcon': {
        color: '#b4b4b4',
      },
    },
  },
  titleButton: {
    color: theme.palette.green,
    fontWeight: 'bold',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  catalogImg: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  catalogImgContainer: {
    height: 240,
  },
  flexLink: {
    display: 'flex',
  },
  selectDesignButton: {
    backgroundColor: 'unset',
    border: '1px solid #15B5B3',
    borderRadius: 20,
    width: '200px',
    color: theme.palette.green,
    '&:hover': {
      backgroundColor: '#9adde1',
      color: 'white',
    },
    '&:disabled': {
      border: '1px solid gray',
      color: theme.palette.gray,
    },
  },
}))

export default useStyles
