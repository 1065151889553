import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
function ViewTemplateContent(props) {
  const { classes, details } = props
  const url_prefix = process.env.REACT_APP_API_STORAGE_URL
  const themes = details.themes?.map((theme) => theme.name).join(', ')
  const seasons = details.seasons?.map((season) => season.name).join(', ')

  const imageDisplay = () => {
    // There's an image set
    if (details.is_published) {
      switch (typeof details.thumbnail_preview_image_url) {
        case 'string': {
          const preview_image_url = `${url_prefix}/${details.thumbnail_preview_image_url}`
          return <img src={preview_image_url} alt="" className={classes.imageIcon} />
        }
        case 'object':
          if (details.thumbnail_preview_image_url !== null) {
            return (
              <img
                src={URL.createObjectURL(details.preview_image_url[0])}
                alt=""
                className={classes.imageIcon}
              />
            )
          }
      }
    }
    const src_image_url = '/images/no_image.png'

    return <img src={src_image_url} alt="no image" className={classes.imageIcon} />
  }
  return (
    <>
      <Grid item xs={5} className={classes.formGroupContainer}>
        <div className={classes.uploadGroupContainer}>
          <label color="primary" htmlFor="image_url">
            {imageDisplay()}
          </label>
        </div>
      </Grid>
      <Grid item xs={7} className={classes.formGroupContainer}>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            テンプレート ID :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            {details.id}
          </Typography>
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            テンプレート名 :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            {details.name}
          </Typography>
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            サイズ :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            W{details.width} X H{details.height}
          </Typography>
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            催事(月) :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            {seasons}
          </Typography>
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            テーマ :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            {themes}
          </Typography>
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            作成者名 :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            {details.owner_name}
          </Typography>
        </div>
        <div className={classes.formGroup}>
          <Typography variant="h6" className={classes.textInputLabel}>
            状態 :
          </Typography>
          <Typography variant="h6" className={classes.textViewContent}>
            {details.is_published === 0 ? '下書き' : '公開済'}
          </Typography>
        </div>
      </Grid>
    </>
  )
}

ViewTemplateContent.propTypes = {
  classes: PropTypes.object,
  details: PropTypes.object,
}

export default ViewTemplateContent
