import * as types from './actionTypes'

const initialState = {
  messages: [],
  chatList: [],
  selectedChatId: null,
  selectedTemplates: [],
  onlineUsers: [],
  printUrl: null,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SET_CHAT_LIST:
      return {
        ...state,
        chatList: payload,
      }
    case types.SET_CHAT_MESSAGES:
      return {
        ...state,
        messages: payload,
      }
    case types.CLEAR_CHAT_MESSAGES:
      return {
        ...state,
        messages: [],
      }
    case types.SET_SELECTED_CHAT_ID:
      return {
        ...state,
        selectedChatId: payload,
      }
    case types.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, payload],
      }
    case types.SET_SELECTED_TEMPLATES:
      return {
        ...state,
        selectedTemplates: payload,
      }
    case types.SET_ONLINE_USERS:
      return {
        ...state,
        onlineUsers: payload,
      }
    case types.SET_PRINT_URL_CHAT:
      return {
        ...state,
        printUrl: payload,
      }
    default:
      return state
  }
}

export default reducer
