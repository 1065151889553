import * as types from './actionTypes'

export function actionUpdateCatalog(catalogDetails) {
  return {
    type: types.UPDATE_CATALOG,
    payload: catalogDetails,
  }
}

export function actionDeleteCatalog(catalogId) {
  return {
    type: types.DELETE_CATALOG,
    payload: catalogId,
  }
}

export function actionSearchCatalog(templateList) {
  return {
    type: types.SEARCH_CATALOG,
    payload: templateList,
  }
}

export function actionSetSearchCriteria(keyword, page, limit, sort, sortBy, theme_ids) {
  return {
    type: types.SET_CATALOG_SEARCH_CRITERIA,
    payload: { keyword, page, limit, sort, sortBy, theme_ids },
  }
}

export function actionSetCatalogDetails(catalogDetails) {
  return {
    type: types.SET_CATALOG_DETAILS,
    payload: catalogDetails,
  }
}

export function actionSetPrintOrderDetails(details) {
  return {
    type: types.SET_PRINT_ORDER_DETAILS,
    payload: details,
  }
}
