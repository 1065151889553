class CanvasSetting {
  /**
   *
   * @param {file} blob
   * @returns image base64 string
   */
  blobToBase64(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  /**
   * @param {object} src
   * @param {float} percentage
   * @returns {promise} object
   */
  getImageSizeUpload(src, refWidth, refHeight) {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = src
      img.onload = (e) => {
        let width = 0
        let height = 0

        const scale_width = refWidth / e.target.width
        const scale_height = refHeight / e.target.height
        const scale = Math.min(scale_width, scale_height)

        width = e.target.width * scale
        height = e.target.height * scale
        resolve({
          width: width,
          height: height,
          origWidth: e.target.width,
          origHeight: e.target.height,
        })
      }
    })
  }
  generateCanvasSpace(size_name) {
    switch (size_name) {
      case 'A4':
        return {
          addedSpace: 275,
          noBorderSpace: 83,
          borderDistance: 40,
          dpi: 350,
          TLinch: 8.3,
          TWinch: 11.7,
        }
      case 'A2':
        return {
          addedSpace: 275,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 16.5,
          TWinch: 23.4,
        }
      case 'A3':
        return {
          addedSpace: 275,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 11.7,
          TWinch: 16.5,
        }
      case 'A5':
        return {
          addedSpace: 275,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 8.26,
          TWinch: 5.82,
        }
      case 'B4':
        return {
          addedSpace: 275,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 13.9,
          TWinch: 9.8,
        }
      case '中型ボード':
        return {
          addedSpace: 158,
          noBorderSpace: 47,
          borderDistance: 23,
          dpi: 200,
          TLinch: 24,
          TWinch: 18,
        }
      case '中型ハーフボード':
        return {
          addedSpace: 276,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 17,
          TWinch: 11,
        }
      case '名刺サイズ':
        return {
          addedSpace: 276,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 3.58,
          TWinch: 2.17,
        }
      default:
        return {
          addedSpace: 275,
          noBorderSpace: 83,
          borderDistance: 41,
          dpi: 350,
          TLinch: 8.26,
          TWinch: 5.82,
        }
    }
  }
  /**
   * params width {number}
   */
  generateCustomCanvasSpace(width, height) {
    const wPx = width * 3.7795
    const wInch = width * 0.039
    const hInch = height * 0.039

    return {
      addedSpace: wPx * 0.1,
      noBorderSpace: wPx * 0.03,
      borderDistance: wPx * 0.03,
      dpi: 350,
      TLinch: hInch,
      TWinch: wInch,
    }
  }

  /**
   * Add canvas components
   */
  addCanvasComponents(canvasRef, borderedCanvasRef) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (canvasRef.current) {
          const cloneComponentRef = canvasRef.current.find('.component-layer')[0].clone()
          const backgroundImg = canvasRef.current.find('.img-background')[0].clone()
          // add background and its component
          if (cloneComponentRef) {
            const borderedCanvas = borderedCanvasRef.current.find('.component-layer')[0]
            borderedCanvas.add(backgroundImg)
            borderedCanvas.add(cloneComponentRef)
          }
        }
        resolve(borderedCanvasRef)
      }, 3000)
    })
  }

  /**
   * Calculate image dpi
   * @return dpi
   */
  checkImageDpi(widthPx, heightPx, widthIn, heightIn) {
    return (widthPx / widthIn + heightPx / heightIn) / 2
  }
}

export default CanvasSetting
