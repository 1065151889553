import * as types from './actionTypes'

export function actionSetChatList(chatList) {
  return {
    type: types.SET_CHAT_LIST,
    payload: chatList,
  }
}

export function actionSetChatMessages(messages) {
  return {
    type: types.SET_CHAT_MESSAGES,
    payload: messages,
  }
}

export function actionClearChatMessages() {
  return {
    type: types.CLEAR_CHAT_MESSAGES,
  }
}

export function actionSetSelectedChatId(chatId) {
  return {
    type: types.SET_SELECTED_CHAT_ID,
    payload: chatId,
  }
}

export function actionAddMessage(message) {
  return {
    type: types.ADD_MESSAGE,
    payload: message,
  }
}

export function actionSetSelectedTemplates(templates) {
  return {
    type: types.SET_SELECTED_TEMPLATES,
    payload: templates,
  }
}

export function actionSetOnlineUsers(users) {
  return {
    type: types.SET_ONLINE_USERS,
    payload: users,
  }
}

export function actionSetPrintUrl(url) {
  return {
    type: types.SET_PRINT_URL_CHAT,
    payload: url,
  }
}
