import { combineReducers } from 'redux'
import auth from './auth/reducer'
import loader from './loader/reducer'
import notification from './notification/reducer'
import users from './adminUser/reducer'
import company from './company/reducer'
import themes from './themes/reducer'
import materials from './materials/reducer'
import templates from './templates/reducer'
import announcement from './announcement/reducer'
import companyAdminusers from './companyAdminUser/reducer'
import loginHistory from './loginHistory/reducer'
import normalUser from './normalUser/reducer'
import adminDashboard from './adminDashboard/reducer'
import companyDashboard from './companyAdminDashboard/reducer'
import catalogs from './catalogs/reducer'
import movieLink from './movieLink/reducer'
import themeCategory from './themeCategory/reducer'
import cart from './cart/reducer'
import orderUploadItems from './orderUploadItems/reducer'
import chat from './chat/reducer'
import orderManagement from './orderManagement/reducer'

const reducers = combineReducers({
  auth,
  loader,
  notification,
  users,
  company,
  themes,
  materials,
  templates,
  announcement,
  companyAdminusers,
  loginHistory,
  normalUser,
  adminDashboard,
  companyDashboard,
  catalogs,
  movieLink,
  themeCategory,
  cart,
  orderUploadItems,
  chat,
  orderManagement,
})

export default reducers
