import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Echo from 'laravel-echo'

import App from 'App'
import * as serviceWorker from 'serviceWorker'
import store from 'store/config'
import { authCheck } from 'store/auth/actionCreators'

window.Pusher = require('pusher-js')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_WEBSOCKET_KEY,
  wsHost: process.env.REACT_APP_WEBSOCKET_HOST,
  cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
  authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  },
  forceTLS: false,
  encrypted: true,
  enableLogging: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
})

// check authentication
store.dispatch(authCheck())

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
