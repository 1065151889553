import { DetailModal } from 'components'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  InputLabel,
  TextField,
  Button,
  Box,
  Grid,
  CircularProgress,
  Chip,
} from '@material-ui/core'
import useStyles from './style'
import clsx from 'clsx'
import CanvasImgGenerator from './CanvasImgGenerator'
import { useSelector, useDispatch } from 'react-redux'
import { getTemplateDetailById } from 'services/users'
import { createChat } from 'services/chat'
import { readCatalog } from 'services/catalog'

const StartChatModal = ({ showModal, closeModal, isCatalog }) => {
  const classes = useStyles()
  const [borderedCanvas, setBorderedCanvas] = useState(null)
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [generatedFiles, setGeneratedFiles] = useState([]) // Store files here
  const selectedTemplates = useSelector((state) => state.chat.selectedTemplates)
  const dispatch = useDispatch()
  const [details, setDetails] = useState(null)
  const [textRequest, setTextRequest] = useState('')

  useEffect(() => {
    if (borderedCanvas && selectedTemplates[currentTemplateIndex] !== undefined) {
      const fileName =
        `${details.name}.png` ?? `design_${selectedTemplates[currentTemplateIndex]}.png`
      const file = dataURLtoFile(borderedCanvas, fileName)
      setGeneratedFiles((prevFiles) => [...prevFiles, file])

      setDetails(null)
      setBorderedCanvas(null)

      if (currentTemplateIndex + 1 < selectedTemplates.length) {
        setCurrentTemplateIndex((prevIndex) => prevIndex + 1)
      } else {
        setIsProcessing(false)
      }
    }
  }, [borderedCanvas])

  useEffect(() => {
    if (selectedTemplates && selectedTemplates.length > 0 && !isProcessing) {
      setCurrentTemplateIndex(0)
      setIsProcessing(true)
    }
  }, [selectedTemplates])

  useEffect(() => {
    if (isProcessing && currentTemplateIndex < selectedTemplates.length) {
      const templateId = selectedTemplates[currentTemplateIndex]
      if (templateId) {
        if (isCatalog) {
          dispatch(readCatalog(templateId)).then((response) => {
            setDetails(response)
          })
        } else {
          dispatch(getTemplateDetailById(templateId)).then((response) => {
            setDetails(response)
          })
        }
      }
    }
  }, [currentTemplateIndex, isProcessing])

  const handleCanvasGenerated = (canvas) => {
    setBorderedCanvas(canvas)
  }

  const handleCloseModal = () => {
    setGeneratedFiles([])
    setIsProcessing(false)
    setBorderedCanvas(null)
    setCurrentTemplateIndex(0)
    closeModal()
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const sendRequest = () => {
    setIsSending(true)
    const data = {
      text: textRequest,
      attachments: generatedFiles,
      templates: selectedTemplates.join(','),
    }

    dispatch(createChat(data))
      .then(() => {
        handleCloseModal()
      })
      .finally(() => {
        setIsSending(false)
      })
  }

  return (
    <DetailModal isOpen={showModal} handleClose={handleCloseModal} variant="download">
      {details && (
        <CanvasImgGenerator details={details} onCanvasGenerated={handleCanvasGenerated} />
      )}
      <Box className={classes.modalContainer}>
        <Typography className={classes.modalTitle}>
          選択されたテンプレートでデザイン依頼を開始します
        </Typography>
        <Grid className={classes.modalInputContainer}>
          <Grid item xs={12} md={3}>
            <InputLabel className={classes.modalLabel} htmlFor="request-details">
              初期
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              className={classes.modalTextInput}
              id="request-details"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={textRequest}
              onChange={(e) => setTextRequest(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid className={classes.modalInputContainer}>
          <Grid item xs={12} md={3}>
            <InputLabel className={classes.modalLabel} htmlFor="templates">
              テンプレート
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            {generatedFiles.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => {
                  setGeneratedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
                }}
                className={classes.modalChip}
              />
            ))}
          </Grid>
        </Grid>
        <Box className={classes.modalButtonContainer}>
          <Button className={classes.modalButtonCancel} onClick={handleCloseModal}>
            <Typography
              variant="h6"
              className={clsx(classes.modalButtonLbl, classes.modalTextWhite)}
            >
              キャンセル
            </Typography>
          </Button>
          <Button
            className={classes.modalButtonStart}
            disabled={isProcessing || isSending || generatedFiles.length === 0}
            onClick={sendRequest}
          >
            {isProcessing || isSending ? (
              <CircularProgress size={20} />
            ) : (
              <Typography
                variant="h6"
                className={clsx(classes.modalButtonLbl, classes.modalTextGreen)}
              >
                追加
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </DetailModal>
  )
}

StartChatModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  isCatalog: PropTypes.bool,
}

export default StartChatModal
