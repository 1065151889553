import React from 'react'
import { Group, Rect, Line } from 'react-konva'
import PropTypes from 'prop-types'

CanvasBorder.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  addedSpace: PropTypes.number.isRequired,
  lineDistance: PropTypes.number.isRequired,
  borderDistance: PropTypes.number.isRequired,
}

function CanvasBorder(props) {
  const { height, width, lineDistance, addedSpace, borderDistance } = props
  const borderWidth = 3

  return (
    <Group clip={{ width: width, height: height, x: 0, y: 0 }}>
      {/* top cross line */}
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[width / 2, 0, width / 2, addedSpace]}
      />
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[
          width / 2 - addedSpace / 2,
          lineDistance,
          width / 2 + addedSpace / 2,
          lineDistance,
        ]}
      />
      {/* bottom cross line */}
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[width / 2, height - addedSpace, width / 2, height]}
      />
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[
          width / 2 - addedSpace / 2,
          height - lineDistance,
          width / 2 + addedSpace / 2,
          height - lineDistance,
        ]}
      />
      {/* left cross line */}
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[0, height / 2, addedSpace, height / 2]}
      />
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[
          lineDistance,
          height / 2 - addedSpace / 2,
          lineDistance,
          height / 2 + addedSpace / 2,
        ]}
      />
      {/* right cross line */}
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[width - addedSpace, height / 2, width, height / 2]}
      />
      <Line
        fill="black"
        stroke="black"
        shadowForStrokeEnabled={false}
        strokeWidth={borderWidth}
        points={[
          width - lineDistance,
          height / 2 - addedSpace / 2,
          width - lineDistance,
          height / 2 + addedSpace / 2,
        ]}
      />
      {/* borders */}
      <Rect
        width={borderDistance}
        x={addedSpace / 2}
        y={-5}
        height={height + 10}
        strokeWidth={borderWidth}
        stroke="black"
        shadowEnabled={false}
        shadowForStrokeEnabled={false}
      />
      <Rect
        width={width + 10}
        x={-5}
        y={addedSpace / 2}
        height={borderDistance}
        strokeWidth={borderWidth}
        stroke="black"
        shadowForStrokeEnabled={false}
        shadowEnabled={false}
      />
      <Rect
        width={borderDistance}
        x={width - borderDistance - addedSpace / 2}
        y={-5}
        height={height + 10}
        strokeWidth={borderWidth}
        stroke="black"
        shadowForStrokeEnabled={false}
        shadowEnabled={false}
      />
      <Rect
        width={width + 10}
        x={-5}
        y={height - borderDistance - addedSpace / 2}
        height={borderDistance}
        strokeWidth={borderWidth}
        stroke="black"
        shadowForStrokeEnabled={false}
        shadowEnabled={false}
      />
    </Group>
  )
}

export default CanvasBorder
