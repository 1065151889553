import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Group, Text } from 'react-konva'
import TextSetting from 'utils/konva/TextSetting'
import PropTypes from 'prop-types'

VerticalText.propTypes = {
  rangeId: PropTypes.number.isRequired,
  uid: PropTypes.number.isRequired,
  contentText: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  fontColor: PropTypes.string.isRequired,
  fontStyle: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  fontFamily: PropTypes.string.isRequired,
}

function VerticalText(props) {
  const { rangeId, uid, contentText, x, y, fontColor, fontStyle, fontSize, rotation, fontFamily } =
    props
  const groupRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const textSetting = new TextSetting()
  const charToAdjust = ['(', ')', '[', ']', '{', '}']
  const gap = 5

  const textToArr = useMemo(() => {
    return [...contentText]
  }, [contentText])

  /**
   * update y axis for each character to make it vertical
   */
  const updateTextCoordinates = async () => {
    await new Promise((resolve) => {
      const charArr = [...contentText]
      let lastY = 0
      charArr.forEach((char, index) => {
        const element = groupRef.current.find(`.${char}-${index}`)[0]
        const xdiff = charToAdjust.includes(char) ? 17 : 0
        if (element) {
          element.y(lastY)
          lastY = element.height() + lastY + gap
          // check if spicial character update x axis
          if (textSetting.isSpecialCharacter(char)) {
            element.x(parseFloat(fontSize) + xdiff)
          }
        }
      })
      resolve()
    })
    setIsVisible(true)
  }

  useEffect(() => {
    if (groupRef.current) {
      updateTextCoordinates()
    }
    return () => setIsVisible(false)
  }, [contentText, fontSize, groupRef, fontFamily])

  return (
    <>
      <Group
        id={`${rangeId}-${uid}`}
        x={x}
        y={y}
        ref={groupRef}
        isVisible={isVisible}
        rotation={rotation}
        listening={false}
      >
        {textToArr.map((text, index) => (
          <Text
            key={`${text}-${index}`}
            name={`${text}-${index}`}
            text={text}
            fontSize={fontSize ? parseFloat(fontSize) : 1}
            fill={fontColor}
            fontStyle={fontStyle}
            fontFamily={fontFamily}
            rotation={textSetting.isSpecialCharacter(text) ? 90 : 0}
            width={parseFloat(fontSize)}
            align="center"
            letterSpacing={fontSize ? parseFloat(fontSize) : 1}
          />
        ))}
      </Group>
    </>
  )
}

export default VerticalText
