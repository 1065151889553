import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox } from '@material-ui/core'

const GreenCheckbox = withStyles({
  root: {
    color: '#15B5B3',
    '&$checked': {
      color: '#15B5B3',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default GreenCheckbox
