import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { signOutUser } from 'services/auth'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import HelpIcon from '@material-ui/icons/Help'
import PersonIcon from '@material-ui/icons/Person'
import { getActiveTemplates } from 'services/users'
import { setOnlineUsers } from 'services/chat'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.white,
  },
  logo: {
    height: 75,
    padding: '10px 0',
    content: 'url(/images/mdl_logo.png)',
  },
  button: {
    margin: theme.spacing(0, 1),
    padding: '10px 20px',
    color: '#46c0a6',
    boxShadow: 'none',
    backgroundColor: 'white',
    height: 40,
    borderRadius: 20,
    fontWeight: 'bold',
    '&:hover': {
      color: 'white',
      backgroundColor: 'rgba(38, 50, 56, 0.2)',
    },
  },
  paper: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  menu: {
    backgroundColor: '#07b2b7',
    backgroundImage: 'linear-gradient(#46c0a6, #07b2b7)',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  menuItem: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(242, 242, 242, 0.2)',
    },
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
}))

const Topbar = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { className, user, ...rest } = props
  const [open, setOpen] = React.useState(false)
  const urlPrefix =
    user.data.role === 'Administrator'
      ? 'admin'
      : user.data.role === 'CompanyAdmin'
      ? 'company-admin'
      : 'user'
  const anchorRef = React.useRef(null)
  const classes = useStyles()
  const onlineUsers = useSelector((state) => state.chat.onlineUsers)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const onClickCatalog = () => {
    dispatch(getActiveTemplates('MyDesign'))
    history.push(`/user/catalogs`)
  }

  const onClickTemplate = () => {
    dispatch(getActiveTemplates('Template'))
    history.push(`/user/catalogs`)
  }

  useEffect(() => {
    window.Echo.join('user-online')
      .here((users) => {
        dispatch(setOnlineUsers(users))
      })
      .joining((user) => {
        dispatch(setOnlineUsers(onlineUsers.concat(user)))
      })
      .leaving((user) => {
        dispatch(setOnlineUsers(onlineUsers.filter((u) => u.id !== user.id)))
      })

    return () => {
      window.Echo.leave('user-online')
    }
  }, [])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to={`/${urlPrefix}/dashboard`}>
          <Typography variant="h4" className={classes.title}>
            <div className={classes.logo}></div>
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        {user.data.role == 'User' && (
          <>
            <RouterLink to={`/${urlPrefix}/order-upload-items`}>
              <Button className={classes.button}>注文のアイテムをアップロードする</Button>
            </RouterLink>
            <Button className={classes.button} onClick={onClickCatalog}>
              マイデザイン
            </Button>
          </>
        )}
        <RouterLink to={`/${urlPrefix}/dashboard`}>
          <Button className={classes.button}>ダッシュボード</Button>
        </RouterLink>
        {user.data.role != 'User' ? (
          <RouterLink to={`/${urlPrefix}/logs`}>
            <Button className={classes.button}>ログ情報</Button>
          </RouterLink>
        ) : (
          <Button className={classes.button} onClick={onClickTemplate}>
            デザイン一覧
          </Button>
        )}
        <RouterLink to={`/${urlPrefix}/chats`}>
          <Button className={classes.button}>チャット</Button>
        </RouterLink>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.button}
            style={{ minWidth: 200, justifyContent: 'space-around' }}
          >
            <div style={{ width: '100%' }}>{user.data.full_name}</div>
            <ExpandMoreIcon />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
            modifiers={{
              offset: {
                enabled: true,
                offset: '-20, 0',
              },
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      className={classes.menu}
                    >
                      <MenuItem
                        className={classes.menuItem}
                        component={RouterLink}
                        to={`/${urlPrefix}/profile`}
                      >
                        <PersonIcon className={classes.icon} />
                        アカウント設定
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        component={RouterLink}
                        to={`/${urlPrefix}/inquiry`}
                      >
                        <HeadsetMicIcon className={classes.icon} />
                        お問い合わせ
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        component={RouterLink}
                        to={`/${urlPrefix}/faq`}
                      >
                        <HelpIcon className={classes.icon} />
                        FAQ
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => dispatch(signOutUser())}
                      >
                        <ExitToAppIcon className={classes.icon} />
                        ログアウト
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
}

export default Topbar
