import React, { useEffect, useState } from 'react'
import { Stage, Layer, Rect, Text, Group } from 'react-konva'
import VerticalText from 'components/Konva/VerticalText'
import ImageKonva from 'components/Konva/Image'
import BackgroundImageKonva from 'components/Konva/BackgroundImage'
import CanvasBorder from './components/konva/canvasBorder'
import PropTypes from 'prop-types'

TemplateCanvas.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  bgImage: PropTypes.string.isRequired,
  background_fit: PropTypes.string.isRequired,
  ranges: PropTypes.array,
  canvasRef: PropTypes.object,
  borderedCanvasRef: PropTypes.object,
  canvasSpace: PropTypes.object.isRequired,
}

function TemplateCanvas(props) {
  const {
    width,
    height,
    bgImage,
    background_fit,
    ranges,
    canvasRef,
    borderedCanvasRef,
    canvasSpace,
  } = props
  const [rangeValues, setRangeValues] = useState([])
  const addedSpace = canvasSpace.addedSpace ?? 0
  const noBorderSpace = canvasSpace.noBorderSpace ?? 0
  const borderDistance = canvasSpace.borderDistance ?? 0

  useEffect(() => {
    const partsRange = ranges
      .filter((val) => val.is_variable === 0)
      .map((range) => {
        return {
          ...range,
          components: range.components.sort((a, b) => a.z - b.z),
        }
      })
      .sort((a, b) => a.z - b.z)
    const variableRange = ranges
      .filter((val) => val.is_variable === 1)
      .map((range) => {
        return {
          ...range,
          components: range.components.sort((a, b) => a.z - b.z),
        }
      })
      .sort((a, b) => a.z - b.z)

    setRangeValues(JSON.parse(JSON.stringify([...variableRange, ...partsRange])).reverse())

    return () => setRangeValues([])
  }, [ranges])

  return (
    <>
      <Stage
        ref={canvasRef}
        width={width - noBorderSpace}
        height={height - noBorderSpace}
        x={-(noBorderSpace / 2)}
        y={-(noBorderSpace / 2)}
      >
        <Layer listening={false}>
          <Rect
            fill="white"
            width={width}
            height={height}
            listening={false}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            hitStrokeWidth={0}
          />
          <BackgroundImageKonva
            image_url={bgImage}
            height={height}
            width={width}
            background_fit={background_fit}
            name="img-background"
          />
        </Layer>
        <Layer listening={false}>
          <Group listening={false} name="component-layer">
            {rangeValues.map((val, index) => {
              const components = JSON.parse(JSON.stringify(val.components)).reverse()

              return (
                <Group
                  key={index}
                  clip={{ width: val.width, height: val.height, x: val.x, y: val.y }}
                >
                  {components.map((component, i) => {
                    return (
                      <Group key={i} listening={false}>
                        {component.type === 'text' ? (
                          <>
                            {component.is_vertical === 1 ? (
                              <VerticalText
                                rangeId={val.uid}
                                uid={component.uid}
                                contentText={component.content_text}
                                x={component.x}
                                y={component.y}
                                fontColor={component.font_color}
                                fontStyle={component.font_style}
                                fontSize={component.font_size}
                                fontFamily={component.font_family}
                                rotation={component.rotation}
                              />
                            ) : (
                              <Text
                                text={component.content_text}
                                x={component.x}
                                y={component.y}
                                fill={component.font_color}
                                fontStyle={component.font_style}
                                fontSize={component.font_size}
                                wrap="char"
                                fontFamily={component.font_family}
                                align={component.alignment}
                                lineHeight={1.25}
                                listening={false}
                                rotation={component.rotation}
                              />
                            )}
                          </>
                        ) : (
                          <ImageKonva
                            name="canvas-image"
                            id={`${val.id}-${component.id}`}
                            image_url={component.image_url}
                            is_variable={component.is_variable}
                            x={component.x}
                            y={component.y}
                            width={component.width}
                            height={component.height}
                            rotation={component.rotation}
                          />
                        )}
                      </Group>
                    )
                  })}
                </Group>
              )
            })}
          </Group>
        </Layer>
      </Stage>
      {/* bordered canvas */}
      <Stage width={width + addedSpace} height={height + addedSpace} ref={borderedCanvasRef}>
        <Layer listening={false}>
          <Rect
            width={width + addedSpace}
            height={height + addedSpace}
            fill="white"
            listening={false}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            hitStrokeWidth={0}
          />
          <CanvasBorder
            height={height + addedSpace}
            width={width + addedSpace}
            addedSpace={addedSpace}
            lineDistance={noBorderSpace}
            borderDistance={borderDistance}
          />
          <Rect
            width={width + 5}
            height={height + 5}
            fill="white"
            x={addedSpace / 2 - 5 / 2}
            y={addedSpace / 2 - 5 / 2}
            shadowForStrokeEnabled={false}
            listening={false}
            perfectDrawEnabled={false}
            hitStrokeWidth={0}
          />
        </Layer>
        <Layer listening={false} x={addedSpace / 2} y={addedSpace / 2}>
          <Group
            name="component-layer"
            clip={{
              width: width,
              height: height,
              x: 0,
              y: 0,
            }}
            listening={false}
          ></Group>
        </Layer>
      </Stage>
    </>
  )
}

export default TemplateCanvas
