import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionUpdateCatalog,
  actionDeleteCatalog,
  actionSearchCatalog,
  actionSetSearchCriteria,
  actionSetCatalogDetails,
  actionSetPrintOrderDetails,
} from 'store/catalogs/actionCreators'

export function generateInitialCatalog(catalogId) {
  return async (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/catalogs/generate-initial/' + catalogId)
      .then(({ data }) => {
        data.data.template_id = catalogId
        dispatch(actionSetCatalogDetails(data.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function generateInitialPrintOrder(catalogId) {
  return async (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/catalogs/print-order/' + catalogId)
      .then(({ data }) => {
        dispatch(actionSetPrintOrderDetails(data.data))
        return data.data
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function generateTemplateInitialPrintOrder(templateId) {
  return async (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/catalogs/template-print-order/' + templateId)
      .then(({ data }) => {
        dispatch(actionSetPrintOrderDetails(data.data))
        return data.data
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function createPrintOrder(printOrderDetails) {
  let formData = new FormData()
  for (const [key, value] of Object.entries(printOrderDetails)) {
    formData.append(key, value)
  }

  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('users/print-order', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        return response.data.data
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function readCatalog(catalogId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('users/catalogs/' + catalogId)
      .then((response) => {
        dispatch(actionSetCatalogDetails(response.data.data))
        return response.data.data
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateCatalog(catalogDetails, catalogId) {
  const formData = new FormData()
  formData.append('name', catalogDetails.name)
  formData.append('current_step', catalogDetails.current_step)
  formData.append('template_id', catalogDetails.template_id)
  formData.append('template_size_id', catalogDetails.template_size_id)
  formData.append('background_fit', catalogDetails.background_fit)
  formData.append('background_image_url', catalogDetails.background_image_url)
  formData.append('width', catalogDetails.width)
  formData.append('height', catalogDetails.height)
  formData.append('_method', 'PUT')

  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('users/catalogs/' + catalogId, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        dispatch(actionUpdateCatalog(response.data.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateCatalogSettings(catalogDetails, catalogId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.put(`users/catalogs/${catalogId}`, catalogDetails)
      .then((response) => {
        return response.data.data
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateCatalogImages(catalogDetails, templateId) {
  const formData = new FormData()
  formData.append('name', catalogDetails.name)
  formData.append('canvas_image', catalogDetails.canvas_image)

  if (catalogDetails.range) {
    for (const [rangeIndex, rangeValues] of Object.entries(catalogDetails.range)) {
      for (const [key, value] of Object.entries(rangeValues)) {
        if (typeof value !== 'object') {
          if (key === 'id') {
            formData.append(`range[${rangeIndex}][${key}]`, value)
          }
        }
        if (typeof value === 'object' && value !== null) {
          for (const [componentIndex, componentValue] of Object.entries(value)) {
            for (const [componentKey, componentData] of Object.entries(componentValue)) {
              if (componentKey === 'image_url' || componentKey === 'id') {
                formData.append(
                  `range[${rangeIndex}][components][${componentIndex}][${componentKey}]`,
                  componentData
                )
              }
            }
          }
        }
      }
    }
  }
  formData.append('_method', 'PUT')
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post(`users/catalogs/${templateId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        dispatch(actionUpdateCatalog(response.data.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function deleteCatalog(catalogIds) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.delete('users/catalogs/' + catalogIds)
      .then(() => {
        dispatch(actionDeleteCatalog(catalogIds))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function searchCatalog(keyword, page, limit, sort, sortBy, theme_ids, viewList) {
  theme_ids = theme_ids ? theme_ids.join(',') : ''
  return (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/catalogs', {
      params: {
        keyword,
        page,
        limit,
        sort,
        sortBy,
        theme_ids: theme_ids,
        viewList,
      },
    })
      .then((response) => {
        dispatch(actionSearchCatalog(response.data))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(keyword, page, limit, sort, sortBy, theme_ids) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(keyword, page, limit, sort, sortBy, theme_ids))
  }
}

export function createLogs(templateId, catalogId) {
  return () => {
    return Http.post('users/catalogs/logs', {
      template_id: templateId,
      catalog_id: catalogId,
    }).catch((error) => {
      console.log(error)
      // TODO Handle error throw a snackbar, alert, toast, or something
    })
  }
}
