class TextSetting {
  constructor() {
    // expression for special character
    this.regex = /([()]|[（）]|ー|[{}]|[<>＜＞]|[~～]|[[]|(])|([「」])|([『』])|([【】])|(→)|(⇒))/g
  }

  /**
   *
   * @param {string} char
   * search match base in expression
   * @returns bool
   */
  isSpecialCharacter(char) {
    const search = char.match(this.regex)

    return search ? true : false
  }
}

export default TextSetting
