import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSetChatList,
  actionSetChatMessages,
  actionSetSelectedChatId,
  actionAddMessage,
  actionSetSelectedTemplates,
  actionSetOnlineUsers,
  actionClearChatMessages,
  actionSetPrintUrl,
} from 'store/chat/actionCreators'

const getFormData = (data) => {
  let formData = new FormData()

  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== '') {
      if (key === 'attachments' && Array.isArray(value)) {
        value.forEach((file, index) => {
          formData.append(`attachments[${index}]`, file)
        })
        continue
      }
      formData.append(key, value)
    }
  }

  return formData
}

export function acceptChatRequest(chatId) {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      const response = await Http.post(`/chat/${chatId}/accept`)
      return response.data
    } catch (error) {
      console.error(error)
    }

    dispatch(hideLoader())
  }
}

export function fetchChatList() {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      const response = await Http.get('/chat')
      dispatch(actionSetChatList(response.data))
      return response.data
    } catch (error) {
      console.error(error)
    }

    dispatch(hideLoader())
  }
}

export function fetchChatMessages(chatId) {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      const response = await Http.get(`/chat/${chatId}`)
      dispatch(actionSetChatMessages(response.data.data))
    } catch (error) {
      console.error(error)
      throw error
    }

    dispatch(hideLoader())
  }
}

export function clearChatMessages() {
  return (dispatch) => {
    dispatch(actionClearChatMessages())
  }
}

export function sendMessage(chatId, data) {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      const formData = getFormData(data)

      const response = await Http.post(`/chat/${chatId}`, formData)

      dispatch(actionSetChatMessages(response.data.data))
    } catch (error) {
      console.error(error)
    }

    dispatch(hideLoader())
  }
}

export function createChat(data) {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      const formData = getFormData(data)

      const response = await Http.post('/chat', formData)

      return response.data
    } catch (error) {
      console.error(error)
    }

    dispatch(hideLoader())
  }
}

export function printOrder(chatId) {
  return async (dispatch) => {
    dispatch(showLoader())

    try {
      const response = await Http.post(`/chat/${chatId}/print-order`)
      dispatch(actionSetPrintUrl(response.data.data?.url))
    } catch (error) {
      console.error(error)
    }

    dispatch(hideLoader())
  }
}

export function clearPrintUrl() {
  return (dispatch) => {
    dispatch(actionSetPrintUrl(null))
  }
}

export function setSelectedChatId(chatId) {
  return (dispatch) => {
    dispatch(actionSetSelectedChatId(chatId))
  }
}

export function addMessage(message) {
  return (dispatch) => {
    dispatch(actionAddMessage(message))
  }
}

export function setSelectedTemplates(templates) {
  return (dispatch) => {
    dispatch(actionSetSelectedTemplates(templates))
  }
}

export function setOnlineUsers(users) {
  return (dispatch) => {
    dispatch(actionSetOnlineUsers(users))
  }
}
