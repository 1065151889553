import React, { useEffect, useState } from 'react'
import { Image } from 'react-konva'
import CanvasSetting from 'utils/konva/CanvasSetting'
import PropTypes from 'prop-types'

ImageKonva.propTypes = {
  image_url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  is_variable: PropTypes.number.isRequired,
}

function ImageKonva(props) {
  const { image_url, is_variable, ...rest } = props
  const [image, setImage] = useState(null)
  const canvasSetting = new CanvasSetting()

  useEffect(() => {
    if (!image && is_variable === 0) {
      GetImage()
    }

    if (is_variable === 1) {
      GetImage()
    }
  }, [image_url, is_variable])

  const GetImage = async () => {
    const img = new window.Image()
    let image_src = image_url
    if (image_url instanceof File) {
      image_src = await canvasSetting.blobToBase64(image_url)
    } else {
      image_src = `${process.env.REACT_APP_API_STORAGE_URL}/${image_url}`
    }

    const promise = new Promise((resolve) => {
      img.crossOrigin = 'anonymous'
      img.src = image_src
      img.onload = () => {
        resolve(img)
      }
    })

    await promise.then((img) => {
      setImage(img)
    })
  }

  return <Image image={image} {...rest} listening={false} />
}

export default ImageKonva
