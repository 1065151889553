import React, { useEffect, useRef, useState } from 'react'
import { Image } from 'react-konva'
import PropTypes from 'prop-types'

BackgroundImageKonva.propTypes = {
  image_url: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  background_fit: PropTypes.string,
}

function BackgroundImageKonva({ image_url, height, width, background_fit, ...rest }) {
  const [image, setImage] = useState(null)
  const nodeRef = useRef(null)

  const scaleToFill = () => {
    const nodeWidth = nodeRef.current.getClientRect().width
    const nodeHeight = nodeRef.current.getClientRect().height
    const scale = Math.max(width / nodeWidth, height / nodeHeight)
    const x = width / 2 - (nodeWidth / 2) * scale
    const y = height / 2 - (nodeHeight / 2) * scale
    nodeRef.current.setAttrs({
      width: nodeWidth * scale,
      height: nodeHeight * scale,
      x: x,
      y: y,
    })
  }

  const scaleToFit = () => {
    const nodeWidth = nodeRef.current.getClientRect().width
    const nodeHeight = nodeRef.current.getClientRect().height
    const scale = Math.min(width / nodeWidth, height / nodeHeight)
    const x = width / 2 - (nodeWidth / 2) * scale
    const y = height / 2 - (nodeHeight / 2) * scale
    nodeRef.current.setAttrs({
      width: nodeWidth * scale,
      height: nodeHeight * scale,
      x: x,
      y: y,
    })
  }

  useEffect(() => {
    if (nodeRef && image) {
      if (background_fit === 'cover') {
        scaleToFill()
      }

      if (background_fit === 'contain') {
        scaleToFit()
      }
      nodeRef.current.draw()
    }
  }, [background_fit, image, nodeRef])

  useEffect(() => {
    if (image_url) {
      const img = new window.Image()
      img.crossOrigin = 'anonymous'
      img.src = `${process.env.REACT_APP_API_STORAGE_URL}${image_url}`
      img.onload = () => {
        // setState will redraw layer
        setImage(img)
      }
    }
    return () => setImage(null)
  }, [image_url])

  return <Image image={image} {...rest} ref={nodeRef} listening={false} />
}

export default BackgroundImageKonva
