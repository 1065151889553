import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TemplateCanvas from 'components/TemplateSteps/TemplateCanvas'
import CanvasSetting from 'utils/konva/CanvasSetting'
import { changeDpiDataUrl } from 'changedpi'

const CanvasImgGenerator = (props) => {
  const { details, onCanvasGenerated } = props

  const canvasRef = useRef(null)
  const borderedCanvasRef = useRef(null)
  const [canvasSpace, setCanvasSpace] = useState({})
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const canvasSetting = new CanvasSetting()

  useEffect(() => {
    if (canvasRef.current && details) {
      generateCanvasImg()
    }
  }, [canvasSpace])

  useEffect(() => {
    if (details) {
      if (details.template_size_id !== 'custom') {
        setWidth(details.width)
        setHeight(details.height)
        const space = canvasSetting.generateCanvasSpace(details.width)
        setCanvasSpace(space)
      } else {
        const adjustedWidth = details.width * 3.7795
        const adjustedHeight = details.height * 3.7795
        const space = canvasSetting.generateCustomCanvasSpace(adjustedWidth)
        setCanvasSpace(space)
        setWidth(adjustedWidth)
        setHeight(adjustedHeight)
      }
    }
  }, [details])

  const generateCanvasImg = async () => {
    try {
      const componentImagePromises = canvasRef.current.find('.canvas-image').map((component) => {
        return new Promise((resolve, reject) => {
          const componentId = component.getAttr('id')?.split('-')
          if (!componentId) {
            reject('Invalid component ID')
            return
          }
          const range = details.template_range?.find((range) => range.id == componentId[0])
          const componentData = range?.components?.find((comp) => comp.id == componentId[1])
          if (!componentData) {
            reject('Component data not found')
            return
          }

          const img = new window.Image()
          img.src = `${process.env.REACT_APP_API_STORAGE_URL}/${componentData.image_url}`
          img.onload = () => resolve()
          img.onerror = () => reject(`Failed to load image: ${img.src}`)
        })
      })

      await Promise.all(componentImagePromises)

      const borderedCanvas = await canvasSetting.addCanvasComponents(canvasRef, borderedCanvasRef)

      if (borderedCanvas.current) {
        borderedCanvas.current.draw()
        const borderedCanvasImg = borderedCanvas.current.toDataURL()
        const dpiAdjustedImage = changeDpiDataUrl(borderedCanvasImg, canvasSpace.dpi)
        onCanvasGenerated?.(dpiAdjustedImage)
      } else {
        console.error('Bordered canvas is not available')
      }
    } catch (error) {
      console.error('Error generating canvas image:', error)
    }
  }

  return details && width > 0 && height > 0 ? (
    <div style={{ display: 'none' }}>
      <TemplateCanvas
        width={width}
        height={height}
        canvasRef={canvasRef}
        borderedCanvasRef={borderedCanvasRef}
        canvasSpace={canvasSpace}
        bgImage={details.background_image_url}
        background_fit={details.background_fit}
        ranges={details.template_range}
      />
    </div>
  ) : null
}

CanvasImgGenerator.propTypes = {
  details: PropTypes.object,
  onCanvasGenerated: PropTypes.func,
}

CanvasImgGenerator.defaultProps = {
  details: null,
  onCanvasGenerated: () => {},
}

export default CanvasImgGenerator
