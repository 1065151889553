import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    display: 'flex',
    height: '88vh',
    columnGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  ownAvatar: {
    backgroundColor: theme.palette.green,
  },
  otherAvatar: {
    backgroundColor: theme.palette.grey,
  },
  participantsPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 280,
    padding: theme.spacing(2),
  },
  messageArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxHeight: '100%',
  },
  messageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  messageBox: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    columnGap: theme.spacing(1),
  },
  messagePaper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    maxWidth: '70%',
    overflowWrap: 'break-word',
    boxSizing: 'border-box',
  },
  ownMessage: {
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.green,
    color: theme.palette.primary.contrastText,
    '& .MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  otherMessage: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[100],
  },
  messageTimestamp: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  ownTimestamp: {
    alignSelf: 'flex-end',
  },
  otherTimestamp: {
    alignSelf: 'flex-start',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    border: `2px solid ${theme.palette.green}`,
    borderRadius: theme.spacing(2),
  },
  hiddenInput: {
    display: 'none',
  },
  textField: {
    marginRight: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled fieldset': {
        border: 'none', // Remove border when disabled
      },
    },
  },
  sendButton: {
    color: theme.palette.green,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    padding: '0 25px 0 25px',
  },
  modalTitle: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    padding: 5,
    marginBottom: '10px',
  },
  modalLabel: {
    fontSize: 14,
    color: 'white',
    fontWeight: 'bold',
    padding: 10,
  },
  modalInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  modalTextInput: {
    backgroundColor: 'white',
    padding: '2px 4px',
    marginBottom: '20px',
  },
  modalButtonStart: {
    backgroundColor: '#ECF0F1',
    padding: '15px 50px',
    borderRadius: 50,
    '&:hover $modalButtonLbl': {
      color: 'white',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },
  modalButtonCancel: {
    backgroundColor: '#009097',
    padding: '15px 50px',
    borderRadius: 50,
  },
  modalButtonAddFile: {
    padding: '5px 10px',
    borderRadius: 50,
    '&:hover $modalButtonLbl': {
      color: 'white',
    },
  },
  modalButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    columnGap: '10px',
    marginTop: '20px',
  },
  modalTextWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalTextGreen: {
    color: theme.palette.green,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalButtonLbl: {
    fontWeight: 'bold',
  },
  modalChip: {
    backgroundColor: '#ECF0F1',
    color: theme.palette.green,
    margin: '5px',
    '&:focus': {
      backgroundColor: '#ECF0F1',
    },
  },
  modalChipContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: '1px solid white',
    padding: '10px',
    minHeight: '130px',
  },
  thumbnail: {
    maxWidth: '100px',
    maxHeight: '100px',
    margin: '8px',
    cursor: 'pointer',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    outline: 'none',
    position: 'relative',
  },
  viewerHeader: {
    position: 'absolute',
    top: 10,
    right: 10,
    display: 'flex',
    gap: '8px',
  },
  viewerImage: {
    maxWidth: '90%',
    maxHeight: '90%',
    borderRadius: '8px',
  },
  viewerIconButton: {
    color: 'white',
  },
  chatList: {
    flex: 1,
    overflowY: 'auto',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      backgroundColor: theme.palette.green,
    },
  },
}))

export default useStyles
